<template>
	<div>
		<searchCard>
			<div class="flex">
				<div class="w-1/4">
					<h6 class="w-1/1">작성 일자</h6>
					<div class="flex">
						<b-form-radio class="w-20 mt-1.5" v-model="opt.period" name="searchPeriod" value="">
							<span class="mr-2">전체</span>
						</b-form-radio>
						<b-form-radio class="mt-1.5" v-model="opt.period" name="searchPeriod" value="period" />

						<datePicker placeholder="from" model="opt.startDate" />
						<span class="p-2 lh-6">~</span>
						<datePicker placeholder="to" model="opt.endDate" />
					</div>
				</div>
				<div class="w-1/7 pl-4">
					<h6>신고 접수</h6>
					<vSelect v-model="opt.claimStatus" :clearable="false" :options="claimOpts" />
				</div>
				<div class="w-1/7 pl-4">
					<h6>노출 여부</h6>
					<vSelect v-model="opt.showStatus" :clearable="false" :options="showOpts" />
				</div>
				<div class="w-4/9 pl-4">
					<h6>검색어</h6>
					<div class="flex">
						<vSelect class="w-80" v-model="opt.searchKeyName" :clearable="false" :options="keywordOpts" />
						<b-form-input
							class="mx-4"
							v-model.trim="opt.searchKeyword"
							name="keyword"
							placeholder="검색어"
							@keyup.enter="search"
						/>
						<b-button class="w-40 px-6" @click="search">검색</b-button>
					</div>
				</div>
			</div>
		</searchCard>

		<b-card>
			<b-button class="absolute right-0" @click="excelExport">엑셀 다운로드 요청</b-button>
			<tb class="mt-12" :inf="inf" :res="res" />
		</b-card>

		<productReviewPopup />
		<reviewHistoryPopup />
	</div>
</template>

<script>
import productReviewPopup from 'pages/reviewPopup'
import reviewHistoryPopup from 'pages/reviewHistoryPopup'

const now = new Date(),
	claimOpts = [
		{ label: '전체', value: 'All' },
		{ label: 'Y', value: 'Exist' },
		{ label: 'N', value: 'None' },
	],
	//딜 요청 상태
	showOpts = [
		{ label: '전체', value: 'All' },
		{ label: '노출', value: 'Show' },
		{ label: '미노출', value: 'Hide' },
	],
	keywordOpts = [
		{ label: '주문번호', value: 'orderNum', operation: 'equal' },
		{ label: '상품그룹 idx', value: 'productGroupIdx', operation: 'equal' },
		{ label: '파트너사', value: 'companyName' },
		{ label: '브랜드', value: 'brandName' },
		{ label: '상품명', value: 'productGroupName' },
		{ label: '핫트아이디', value: 'hottId' },
	],
	defOpt = {
		period: 'period',
		startDate: getMonth(now, -1),
		endDate: now,

		claimStatus: claimOpts[0],
		showStatus: showOpts[0],
		searchKeyName: keywordOpts[0],
		searchKeyword: '',
	}

export default {
	components: { productReviewPopup, reviewHistoryPopup },
	data() {
		return {
			claimOpts,
			showOpts,
			keywordOpts,

			opt: { ...defOpt },
			defOpt,

			inf: [
				{ title: 'No.', model: 'idx', size: 1 },
				{ title: '작성일자', key: 'createDt', size: 3 },
				{ title: '핫트 아이디', key: 'hottId', size: 2 },
				{ title: '주문번호', key: 'orderNum', size: 2 },
				{ title: 'IDX', key: 'productGroupIdx', size: 1 },
				{ title: '파트너사', key: 'companyName', size: 2 },
				{ title: '브랜드', key: 'brandName', size: 2 },
				{ title: '상품명', key: 'productGroupName', size: 3, notCenter: 1 },
				{ title: '옵션명', key: 'productOptionName', size: 2, notCenter: 1 },
				{ title: '별점', key: 'reviewScore', size: 1 },
				{ title: '도움 수', key: 'likeCount', size: 1 },
				{ title: '상품 리뷰', key: 'reviewContent', size: 6, notCenter: 1 },
				{ title: '리뷰<br>글자수', key: 'reviewContentCount', size: 2 },
				{ title: '추가 의견', key: 'additionalComment', size: 3, notCenter: 1 },
				{ title: '의견<br>글자수', key: 'additionalCommentCount', size: 2 },
				{ title: '신고접수', key: 'claimCount', size: 1 },
				{ title: '신고 사유', key: 'reasonType', size: 2 },
				{
					title: '숨김',
					model: 'button',
					size: 2,
					text: item => (item.isShow ? '숨김' : '해제'),
					bVariant: item => (item.isShow ? 'primary' : 'gray'),
					func: this.changeShowPopup,
				},
				{
					title: '신고 내역',
					model: 'button',
					size: 1,
					icon: 'journal-check',
					func: this.showReviewHistoryPopup,
				},
			],
			lastOpt: {},
			res: {},
		}
	},
	methods: {
		search(operation = 'LIKE') {
			if (typeof operation != 'string') operation = 'LIKE'
			const opt = this.opt,
				error = [],
				data = {
					orderBy: getOrder('createDt'),
					paging: getPaging(0, 10),
					claimStatus: getDropdownValue(opt.claimStatus),
					showStatus: getDropdownValue(opt.showStatus),
					isWholeDate: opt.period == '',
				}

			if (opt.period) {
				if (opt.startDate > opt.endDate) error.push(opt.dateType.label + '의 시작일이 종료일보다 큽니다')
				else {
					data.startDate = get_time('d', 0, '', opt.startDate)
					data.endDate = get_time('d', 0, '', opt.endDate)
				}
			}

			if (opt.searchKeyword) {
				const searchKey = getDropdownValue(opt.searchKeyName)
				data.searchKey = getSearchKey([opt.searchKeyName], opt.searchKeyword, operation, 'and')
			}

			if (error.length) alert.w(error)
			else {
				this.lastOpt = data
				this.changePage()
			}
		},
		changePage(page = 1, ex = false) {
			this.lastOpt.paging.pageNo = page - 1
			postApi('api/backoffice/productSelect/productReviewList', addExcelData(this, ex)).then(res => {
				if (!ex) {
					res.list = res.list.map(v => {
						v.createDt = v.createDt.replace('T', ' ')
						v.reviewScore = v.reviewScore * 1 + '점'
						v.claimCount = v.claimCount ? `Y\r\n(${v.claimCount}회)` : 'N'
						return v
					})
					this.res = res
				} else alert.excel()
			})
		},
		changeReviewStatus(productReviewSeq, isShow = false, baseData = {}) {
			return putApi('api/backoffice/productHandle/updateProductReviewStatus', {
				productReviewSeq,
				reviewStatus: isShow ? 'APPROVED' : 'DENIED',
				...baseData,
			}).then(() => {
				alert.s('상태 변경에 성공했습니다.')
				reload(this)
			})
		},
		changeShowPopup(item) {
			if (item.isShow) this.reviewPopup.open(item)
			else
				confirm('리뷰 숨김을 해제하시겠습니까?', () => {
					this.changeReviewStatus(item.productReviewSeq, true)
				})
		},
		showReviewHistoryPopup(item) {
			this.reviewHistoryPopup.open(item)
		},

		excelExport() {
			const opt = this.lastOpt,
				ex = new excel()

			ex.date(opt.startDate, opt.endDate, '작성일자')
			ex.dropdown(opt.claimStatus, this.claimOpts, '신고 접수', '전체')
			ex.dropdown(opt.showStatus, this.showOpts, '노출 여부', '전체')
			ex.search(opt.searchKey, this.keywordOpts)
			ex.go(this)
		},
	},
	created() {
		this.search()
	},
}
</script>
