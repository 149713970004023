<template>
	<basePopup :title="title" cancelText="닫기" okText="" size="lg">
		<b-card> <tb :inf="inf" :res="res" /> </b-card>
	</basePopup>
</template>

<script>
import basePopup from 'comp/local/basePopup'

export default {
	props: { cName: { default: 'reviewHistoryPopup' } },
	components: { basePopup },
	data() {
		return {
			title: '',
			isShow: false,

			origin: '',

			inf: [
				{ title: 'No.', model: 'idx', size: 1 },
				{ title: '신고일자', key: 'productReviewClaimDt', size: 2 },
				{ title: '신고 사유', key: 'reasonType', size: 6 },
				{ title: '신고인 ID', key: 'hottId', size: 2 },
				{ title: '숨김/해제 처리 내역', key: 'comment', size: 10, notCenter: 1 },
			],
			lastOpt: {},
			res: {},
		}
	},
	methods: {
		open(item) {
			this.origin = item
			this.title = `${item.hottId} / ${item.orderNum} / ${item.productGroupName} 신고 내역`

			this.lastOpt = {
				productReviewSeq: item.productReviewSeq,
				paging: getPaging(),
			}
			this.changePage()

			this.isShow = true
		},
		changePage(page = 1) {
			this.lastOpt.paging.pageNo = page - 1
			postApi('api/backoffice/productSelect/productReviewHistoryList', this.lastOpt).then(res => {
				res.list = res.list.map(v => {
					v.productReviewClaimDt = v.productReviewClaimDt ? v.productReviewClaimDt.replace('T', ' ') : ''
					return v
				})
				this.res = res
			})
		},
	},
}
</script>
