<template>
	<basePopup :okFunc="save" okText="숨김 처리하기" size="md" title="관리자 리뷰 숨김">
		<h6>숨김 사유</h6>
		<div v-for="(v, i) in reasonTypeOpts" class="flex top-1.5">
			<b-form-radio v-model="input.reasonType" name="hideReviewReason" :value="v">
				<span class="mr-6" v-text="v.label" />
			</b-form-radio>
			<b-form-textarea
				v-if="v.value == 'ETC'"
				class="resize-none w-92"
				v-model="input.reasonDescription"
				placeholder="숨김 사유를 작성하세요."
				rows="2"
			/>
		</div>
	</basePopup>
</template>

<script>
import basePopup from 'comp/local/basePopup'

const reasonTypeOpts = [
	{ label: '관련 없는 내용', value: 'UNRELATED' },
	{ label: '욕설/ 비방', value: 'BLAME' },
	{ label: '광고/ 홍보글', value: 'ADVERTISEMENT' },
	{ label: '음란/ 선정성', value: 'ADULT' },
	{ label: '기타', value: 'ETC' },
]
export default {
	props: { cName: { default: 'reviewPopup' } },
	components: { basePopup },
	data() {
		return {
			isShow: false,

			origin: '',
			reasonTypeOpts,
			input: {
				reasonType: reasonTypeOpts[4],
				reasonDescription: '',
			},
		}
	},
	methods: {
		open(item) {
			this.origin = item

			this.isShow = true
		},
		save() {
			const input = this.input,
				origin = this.origin
			this.p
				.changeReviewStatus(origin.productReviewSeq, false, {
					reasonType: getDropdownValue(input.reasonType),
					reasonDescription: input.reasonDescription,
				})
				.then(() => (this.isShow = false))
		},
	},
}
</script>
